import { GatsbyButton } from '@components/atm.button/button.component';
import { YesNo } from '@global/utils/domain/entities';
import { Assesment, Assesment_Insert_Input, Subscription_Insert_Input } from '@global/utils/remote-graphql-types';
import { Body, Col, FeaturedH2, Grid, H1, InputLabel, Row, Separator } from '@web/atomic';
import { CheckboxField } from '@web/atomic/legacy/atm.checkbox';
import { RadioField } from '@web/atomic/legacy/atm.radio';
import { PhoneField } from '@web/atomic/legacy/atm.text-field/phone-field.component';
import { TextField } from '@web/atomic/legacy/atm.text-field/text-field.component';
import { ForcedFade } from '@web/atomic/legacy/obj.animation/animation.component.style';
import flashDispatcherService from '@web/atomic/legacy/obj.flash-wrapper/flash-dispatcher.service';
import { Form, FormData, Validators } from '@web/atomic/legacy/obj.form';
import { useField } from '@web/atomic/obj.custom-hooks/field.hook';
import { RelativeWrapper } from '@web/atomic/obj.wrappers';
import { useMutationCustom } from '@web/data/use-mutation-custom.hook';
import { getCanonicalUrl } from '@web/utils/url';
import { graphql, navigate } from 'gatsby';
import { GatsbyImage, getSrc } from 'gatsby-plugin-image';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { SEO } from '../components/legacy/mol.seo/seo.component';
import IndexLayout from '../components/org.layout/layout.component';
import { SubscriptionSource } from '../components/org.subscription-form/subscription-form.component';
import assesmentDatasource from '../data/assesment.datasource';
import defaultClient from '../data/graphql/default-client';
import { AssesmentMutation } from '../data/graphql/hasura/assesment.mutation.hasura';
import { File, Query } from '../data/graphql/local-graphql-types';
import {
  SleepCondition,
  SleepProblemAge,
  SleepProblemCause,
  SleepProblemChallenge,
  SleepProblemFrequency,
  SleepSolution,
  TimeInterval,
} from '../data/quiz-typeform.model';
import { PageProps } from '../utils/local-types';
import { appPaths } from '../utils/path';

export interface StartPageLocationState {
  initialValue?: SleepProblemAge;
}

export interface CustomQuery extends Query {
  girl: File;
}

const NoneOfAbove = 'none';

const StartPage: React.FunctionComponent<PageProps<unknown, CustomQuery>> = (props) => {
  const [assesment, setAssesment] = useState<Assesment_Insert_Input>();
  const [submitSleepDiaryItem, { data: submitData, error, loading: submitLoading }] = useAssesmentMutation(assesment);
  const handleSubmit = useCallback(
    async (formData: FormData<StartingFormData>) => {
      if (Object.keys(formData.error).length !== 0) return;
      const [objects, subscription] = await buildSleepMutationVar(formData.data);
      setAssesment(objects);
      await submitSleepDiaryItem({ variables: { objects, subscription } });
    },
    [submitSleepDiaryItem]
  );

  const [challenge, handleChallenge] = useField();

  const locationState: StartPageLocationState = props.location.state;

  return (
    <IndexLayout {...props}>
      <SEO
        socialMedia={{
          canonicalUrl: getCanonicalUrl(props.data.site.siteMetadata.siteUrl, props.location.pathname),
          title: 'Saiba se a Vigilantes do Sono é pra você',
          description: 'Faça esta avaliação da sua insônia para ver se a Vigilantes do Sono pode te ajudar.',
          image: getSrc(props.data.girl.childImageSharp.gatsbyImageData),
          imageAlt: 'menina com máscara',
        }}
      />

      <Grid>
        <Form onSubmit={handleSubmit}>
          <Row mt center="xs">
            <Col xs={12} sm={8}>
              <RelativeWrapper>
                <GatsbyImage image={props.data.girl.childImageSharp.gatsbyImageData} alt="garota com máscara" />
                <GetStartTitleWrapper>
                  <H1 center>Começar teste grátis</H1>
                </GetStartTitleWrapper>
              </RelativeWrapper>
            </Col>
          </Row>

          <Row mb center="xs">
            <Col xs={12} sm={8}>
              <Separator />
              <Body>
                Descubra como o programa da Vigilantes do Sono pode te ajudar, qual seu tipo insônia e veja uma dica de sono para você
                aplicar já hoje à noite!
              </Body>
              <FeaturedH2>Sobre seu sono</FeaturedH2>
            </Col>
          </Row>

          <Row mb center="xs">
            <Col xs={12} sm={8}>
              <Form.Field name="age" initialValue={locationState && locationState.initialValue} validators={[Validators.Required()]}>
                <InputLabel>
                  Há <strong>quanto tempo</strong> você tem problemas para dormir?
                </InputLabel>
                <Separator />
                {Object.values(SleepProblemAge)
                  // filter first option
                  .slice(1)
                  .map((value) => (
                    <RadioField key={value} id={value}>
                      {value}
                    </RadioField>
                  ))}
              </Form.Field>
              <Separator />
            </Col>
          </Row>

          <Row mb center="xs">
            <Col xs={12} sm={8}>
              <Form.Field name="freq" validators={[Validators.Required()]}>
                <InputLabel>
                  Com <strong>que frequência</strong> você sente que esse problema para dormir acontece?
                </InputLabel>
                <Separator />
                {Object.values(SleepProblemFrequency).map((value) => (
                  <RadioField key={value} id={value}>
                    {value}
                  </RadioField>
                ))}
              </Form.Field>
              <Separator />
            </Col>
          </Row>

          <Row mb center="xs">
            <Col xs={12} sm={8}>
              <Form.Field name="challenge" onValueChange={handleChallenge} validators={[Validators.Required()]}>
                <InputLabel>
                  Nestes dias, com o que você enfrenta <strong>mais dificuldade</strong>?
                </InputLabel>
                <Separator />
                {Object.values(SleepProblemChallenge).map((value) => (
                  <RadioField key={value} id={value}>
                    {value}
                  </RadioField>
                ))}
              </Form.Field>
              <Separator />
            </Col>
          </Row>

          {challenge === SleepProblemChallenge.FallSleep && (
            <Row mb center="xs">
              <Col xs={12} sm={8}>
                <ForcedFade show={true}>
                  <Form.Field name="timeToSleep" validators={[Validators.Required()]}>
                    <InputLabel>
                      Considerando uma noite típica do mês passado. <strong>Quanto tempo levou para pegar no sono?</strong>
                    </InputLabel>
                    <Separator />
                    {Object.values(TimeInterval).map((value) => (
                      <RadioField key={value} id={value}>
                        {value}
                      </RadioField>
                    ))}
                  </Form.Field>
                </ForcedFade>
                <Separator />
              </Col>
            </Row>
          )}

          {challenge === SleepProblemChallenge.WakeUpMiddle && (
            <Row mb center="xs">
              <Col xs={12} sm={8}>
                <ForcedFade show={true}>
                  <Form.Field name="wakeUpMiddle" validators={[Validators.Required()]}>
                    <InputLabel>
                      Considerando uma noite típica do mês passado. Se você acordou no meio da noite,{' '}
                      <strong>quanto tempo você permaneceu acordado</strong> no total?
                    </InputLabel>
                    <Separator />
                    {Object.values(TimeInterval).map((value) => (
                      <RadioField key={value} id={value}>
                        {value}
                      </RadioField>
                    ))}
                  </Form.Field>
                </ForcedFade>
                <Separator />
              </Col>
            </Row>
          )}

          {challenge === SleepProblemChallenge.WakeUpEarly && (
            <Row mb center="xs">
              <Col xs={12} sm={8}>
                <ForcedFade show={true}>
                  <Form.Field name="wakeUpEarly" validators={[Validators.Required()]}>
                    <InputLabel>
                      Considerando uma noite típica do mês passado. <strong>Quanto tempo antes do desejado você acordou?</strong>
                    </InputLabel>
                    <Separator />
                    {Object.values(TimeInterval).map((value) => (
                      <RadioField key={value} id={value}>
                        {value}
                      </RadioField>
                    ))}
                  </Form.Field>
                </ForcedFade>
                <Separator />
              </Col>
            </Row>
          )}

          <Row mb center="xs">
            <Col xs={12} sm={8}>
              <Form.Field name="cause" validators={[Validators.Required()]}>
                <InputLabel>
                  Qual dos pontos abaixo <strong>costuma atrapalhar mais</strong> seu sono?
                </InputLabel>
                <Separator />
                {Object.values(SleepProblemCause).map((value) => (
                  <RadioField key={value} id={value}>
                    {value}
                  </RadioField>
                ))}
              </Form.Field>
              <Separator />
            </Col>
          </Row>

          <Row mb center="xs">
            <Col xs={12} sm={8}>
              <Form.Field name="solution">
                <InputLabel>
                  Quais dos pontos a seguir você já recorreu para <strong>melhorar seu sono?</strong>
                </InputLabel>
                <Separator />
                {Object.values(SleepSolution).map((value) => (
                  <CheckboxField key={value} id={value}>
                    {value}
                  </CheckboxField>
                ))}
                <Separator />
                <RadioField id={NoneOfAbove}>Nenhuma das anteriores</RadioField>
              </Form.Field>
              <Separator />
            </Col>
          </Row>

          <Row mb center="xs">
            <Col xs={12} sm={8}>
              <Form.Field name="condition" validators={[Validators.Required()]}>
                <InputLabel>
                  Você já foi diagnosticado em <strong>quais condições médicas</strong> a seguir?
                </InputLabel>
                <Separator />
                {Object.values(SleepCondition).map((value) => (
                  <CheckboxField key={value} id={value}>
                    {value}
                  </CheckboxField>
                ))}
                <Separator />
                <RadioField id={NoneOfAbove}>Nenhuma das anteriores</RadioField>
              </Form.Field>
              <Separator />
            </Col>
          </Row>

          <Row mb center="xs">
            <Col xs={12} sm={8}>
              <FeaturedH2>Sobre você</FeaturedH2>
            </Col>
          </Row>

          <Row mb center="xs">
            <Col xs={12} sm={8}>
              <Form.Field name="firstName" validators={[Validators.Required()]}>
                <InputLabel>
                  Qual seu <strong>primeiro nome</strong>?
                </InputLabel>
                <TextField type={'text'} autoComplete="given-name" />
              </Form.Field>
              <Separator />
            </Col>
          </Row>

          <Row mb center="xs">
            <Col xs={12} sm={8}>
              <Form.Field name="lastName" validators={[Validators.Required()]}>
                <InputLabel>
                  Qual seu <strong>sobrenome</strong>?
                </InputLabel>
                <TextField type={'text'} autoComplete="family-name" />
              </Form.Field>
              <Separator />
            </Col>
          </Row>

          <Row mb center="xs">
            <Col xs={12} sm={8}>
              <Form.Field name="phone" validators={[Validators.PhoneRegex('O número digitado é inválido')]}>
                <InputLabel>
                  Qual seu <strong>celular/whatsapp</strong> (opcional)?
                </InputLabel>
                <PhoneField />
              </Form.Field>
              <Separator />
            </Col>
          </Row>

          <Row mb center="xs">
            <Col xs={12} sm={8}>
              <Form.Field name="email" validators={[Validators.Required(), Validators.EmailRegex('O e-mail preenchido não é válido')]}>
                <InputLabel>
                  Qual seu <strong>e-mail</strong>?
                </InputLabel>
                <TextField type={'email'} autoComplete="email" />
              </Form.Field>
              <Separator />
            </Col>
          </Row>

          <Row mb center="xs">
            <Col xs={12} sm={8}>
              <Form.Field name="subscribe" validators={[Validators.Required()]}>
                <InputLabel>
                  Gostaria de receber e-mails com <strong>dicas de sono ou outras ferramentas</strong>? <br />
                  (Juramos não mandar spam🤞e você poderá cancelar sua inscrição quando quiser)
                </InputLabel>
                <Separator />
                {Object.values(YesNo).map((value) => (
                  <RadioField key={value} id={value}>
                    {value}
                  </RadioField>
                ))}
              </Form.Field>
              <Separator />
            </Col>
          </Row>
          <Row mb center={'xs'}>
            <Col xs={12} sm={8} md={4} lg={4}>
              <GatsbyButton type="submit" kind="primary" disabled={!!submitData && !error} loading={submitLoading} expanded>
                Ver resultados
              </GatsbyButton>
              <Separator />
            </Col>
          </Row>
        </Form>
      </Grid>
    </IndexLayout>
  );
};

export default StartPage;

export const query = graphql`
  query StartQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
    girl: file(relativePath: { eq: "girl-with-mask.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 930, layout: CONSTRAINED)
      }
    }
  }
`;

////////////////////////////////////////////////////////////////////////////////////////////////////
const useAssesmentMutation = (assesment: Assesment_Insert_Input) => {
  const handleSuccess = useCallback(() => {
    flashDispatcherService.dispatchMessage('Sucesso! Você está sendo redirecionado para ver seus resultados!', 'success');
    assesmentDatasource.assesment = assesment as Assesment;
    navigate(appPaths.results.path);
  }, [assesment]);
  return useMutationCustom(AssesmentMutation, { onCompleted: handleSuccess, client: defaultClient });
};

interface StartingFormData {
  age: SleepProblemAge;
  freq: SleepProblemFrequency;
  challenge: SleepProblemChallenge;
  timeToSleep: TimeInterval;
  wakeUpMiddle: TimeInterval;
  wakeUpEarly: TimeInterval;
  cause: SleepProblemCause;
  solution: SleepSolution[];
  condition: SleepCondition[];
  firstName: string;
  lastName: string;
  phone?: string;
  email: string;
  subscribe: YesNo;
}

const buildSleepMutationVar = (input: StartingFormData): [Assesment_Insert_Input, Subscription_Insert_Input] => {
  const subscribe = input.subscribe === YesNo.Yes;
  const obj: Assesment_Insert_Input = {
    age: input.age,
    cause: input.cause,
    challenge: input.challenge,
    condition: Array.isArray(input.condition) ? input.condition.join(';') : null,
    email: input.email,
    first_name: input.firstName,
    frequency: input.freq,
    last_name: input.lastName,
    phone: input.phone,
    solution: Array.isArray(input.solution) ? input.solution.join(';') : null,
    time_to_sleep: input.timeToSleep,
    wake_up_early: input.wakeUpEarly,
    wake_up_middle: input.wakeUpMiddle,
  };
  const subscription: Subscription_Insert_Input = {
    email: input.email,
    source: SubscriptionSource.assesment,
    name: input.firstName,
    subscribed: subscribe,
  };

  return [obj, subscription];
};

////////////////////////////////////////////////////////////////////////////////////////////////////

const GetStartTitleWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: 24px;
  @media all and (min-width: 32em) {
    bottom: 0;
  }
  @media all and (min-width: 64em) {
    bottom: 32px;
  }
`;
