import { AddAssesmentMutation, AddAssesmentMutationVariables } from '@global/utils/remote-graphql-types';
import { TypedDocumentNode } from '@web/data/apollo-client';
import gql from 'graphql-tag';

export const AssesmentMutation: TypedDocumentNode<AddAssesmentMutation, AddAssesmentMutationVariables> = gql`
  mutation AddAssesment($objects: [assesment_insert_input!]!, $subscription: [subscription_insert_input!]!) {
    insert_assesment(objects: $objects) {
      affected_rows
    }

    insert_subscription(objects: $subscription, on_conflict: { update_columns: subscribed, constraint: subscription_pkey }) {
      affected_rows
    }
  }
`;
